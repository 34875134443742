
import { Component, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralserviceService } from '../../Services/generalservice.service';
import { ElementRef, Renderer2 } from '@angular/core';
import Swal from 'sweetalert2';

declare var $: any;



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    
    @ViewChild('myModal3') modalTemplate: TemplateRef<any>;

    public form: FormGroup;
    arr: any = [];
    isLoggedIn: boolean = false;
    logindata: any;
    logindeta; any;
    Username: any;
    OTP: any;
    Pwd: any;
    Confirmpassword: any;
    Mobile: any;
    randomnumber: any;
    //Password: any;
    public email: AbstractControl;
    public Password: AbstractControl;
    passwordshow: boolean = false;
    
    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(private generalservice: GeneralserviceService, public http: HttpClient, private fb: FormBuilder, private router: Router, private elementRef: ElementRef, private renderer: Renderer2) {
        this.logindeta = localStorage.getItem('Logindetals');
        this.form = this.fb.group({
            Mobile: ['',],
            Password: ['',],
            email: ['',],
        })
        this.email = this.form.controls['email'];
        this.Password = this.form.controls['Password'];
    }

    ngOnInit() {
        const loginDetails = JSON.parse(localStorage.getItem('LoginDetails') || '{}');
        this.isLoggedIn = loginDetails.isLoggedIn || false;
        this.Username = loginDetails.Username || null;
    }
    openModal() {
        // Open the modal
        // You can implement your logic here to display the modal
        // For example, you can set a boolean flag to true
    }
    logout() {

        this.isLoggedIn = false;
        this.Username = null;

        // Clear localStorage
        localStorage.removeItem('LoginDetails');
    }
   


    Close() {
        const modal4 = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal4, 'display', 'none');
        const modal5 = this.elementRef.nativeElement.querySelector('#myModal3');
        this.renderer.setStyle(modal5, 'display', 'none');
        const modal6 = this.elementRef.nativeElement.querySelector('#myModal4');
        this.renderer.setStyle(modal6, 'display', 'none');
    }

    public OnSubmit(): void {
        this.arr = [];
        this.arr.push({
            Mobile: this.form.value.email,
            Password: this.form.value.Password,
        });

        if (this.form.valid) {
            var uploadfile = new FormData;
            uploadfile.append("Param1", this.form.value.email);
            uploadfile.append("Param2", this.form.value.Password);
            var url = "api/DressBox/DB_Admin_Login";
            this.generalservice.PostData(url, uploadfile).then((data: any) => {
                if (data != null) {
                    if (data == "Invalid User") {
                        this.generalservice.ShowAlert("Warning", 'Please enter valid credentials, If you are a new user please sign up', 'Warning');
                        this.isLoggedIn = false;
                    } else {
                        localStorage.setItem("LoginDetails", JSON.stringify(data));
                        this.router.navigateByUrl('/navbar').then(() => {
                            this.router.navigate(['/CarTypes']).then(() => {
                                window.location.reload();
                            });
                        });
                    }
                } else {
                    // Handle the case where data is null
                    this.generalservice.ShowAlert("Warning", 'Please enter valid credentials, If you are a new user please sign up', 'Warning');
                    this.isLoggedIn = false;
                }
            }, (err: any) => {
                this.generalservice.ShowAlert("ERROR", 'Something went wrong. Please try again later.', 'error');
                this.isLoggedIn = false;
            });
        }
    }


    ForgotPassword() {
        debugger
        this.Mobile = "";
        this.OTP = "";
        this.Pwd = "";
        this.Confirmpassword = "";
        const modal = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal, 'display', 'block');
    }

    CheckMobile(Mobile) {
        debugger;

        Mobile: Mobile

        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {

            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);
            //UploadFile.append("Flag", "1");
            var url = "api/DressBox/checking_Mobile"
            debugger;
            this.generalservice.PostData(url, UploadFile).then(data => {
                debugger;
                if (data != 'NOTEXIST') {
                    debugger
                    this.OTP = ""
                    this.Mobile = Mobile;
                    this.SendOtpForMobile();
                    const modal2 = this.elementRef.nativeElement.querySelector('#myModal2');
                    this.renderer.setStyle(modal2, 'display', 'none');

                    // Show myModal3
                    const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
                    this.renderer.setStyle(modal3, 'display', 'block');

                }
                else {

                    alert("Please Enter Valid Mobile Number")
                }
            }, err => {
            });
        }

    }

    SendOtpForMobile() {
        debugger

        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " SYCar";
        //var DLTTEID = '1207165215802728246';

        var UploadFile = new FormData();
        UploadFile.append("MobileNo", this.Mobile);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/DressBox/SendOtpToMobile';
        this.generalservice.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {

            }
            else {

            }
        });
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber == otp) {
            const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
            this.renderer.setStyle(modal3, 'display', 'none');

            const modal = this.elementRef.nativeElement.querySelector('#myModal4');
            this.renderer.setStyle(modal, 'display', 'block');
        }
        else {
            alert("Invalid OTP");
        }
    }
    /*end region*/



    /*Change password*/
    ChangePassword(Password, confirmpassword) {
        if (Password == undefined || Password == null || Password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (Password != confirmpassword) {
                alert("password and confirmpassword must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Password", Password);
                UploadFile.append("Mobile", this.Mobile);
                var url = "api/DressBox/Forgot_Password"
                debugger;
                this.generalservice.PostData(url, UploadFile).then(data => {
                    debugger;
                    if (data == 'SUCCESS') {
                        this.generalservice.ShowAlert('SUCCESS', 'Password Changed Successfully.', 'success')
                        const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                        this.renderer.setStyle(modal, 'display', 'none');
                    }
                }, err => {
                    const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                    this.renderer.setStyle(modal, 'display', 'none');
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }

    //public onSubmit(): void {
    //    this.arr = [];
    //    this.arr.push({
    //        EMail: this.form.value.EMail,
    //        password: this.form.value.password,
    //    });
    //    debugger
    //    if (this.form.valid) {
    //        var formData = new FormData();
    //        formData.append("Param", JSON.stringify(this.arr));
    //        var url = "api/SYCar/SYC_Login";
    //        this.generalService.PostData(url, formData)
    //            .then((data: any) => {
    //                debugger
    //                if (data != null) {
    //                    if (data == "Invalid User") {
    //                        //   this.logindata = data;
    //                        this.generalService.ShowAlert("Warning", 'Please Enter Valid Credentials', 'Warning');
    //                    } else {
    //                        localStorage.setItem("LoginDetails", JSON.stringify(data));
    //                        this.router.navigate(['/dashboard']);
    //                    }
    //                }
    //            })
    //            .catch((error: any) => {
    //                console.error("Error occurred:", error);
    //                // Handle error appropriately, show error message to the user
    //                this.generalService.ShowAlert("ERROR", 'Something went wrong. Please try again later.', 'error');
    //            });
    //    }
    //}
}







