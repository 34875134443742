<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a><b style="color:white;">Checkout </b></a></li>

            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">

                    <span><strong>Delivery address</strong></span><br /><h6>{{loginDetails[0].Name}}/{{loginDetails[0].Mobile}}</h6>
                    <div class="card" style="padding:5px;margin-top:3%" *ngFor="let item of  addressDetails let i=index">
                        <span>
                            <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.AID}}" (change)="getSelectedAddress(item.AID)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
                            &nbsp;{{item.Area}}, {{item.HNo}}, {{item.Landmark}},<br />
                            {{item.City}},{{item.State}},{{item.Address}}
                        </span>
                    </div>

                </div>
                <div class="col-lg-3">
                    <div class="card" style="padding:5px;margin-top:20%;width:160%">
                        <h6 style="text-align:center;color:#f4880d">Price Details</h6>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-6"style="margin-top: 10px;"><div>Delivery Charges</div></div>
                            <div class="col-lg-6"style="margin-top: 10px;"><h6>--</h6></div>
                            <hr /> <div class="col-lg-6"style="margin-top: 10px;"> <div>Total Payable:</div> </div>
                            <div class="col-lg-6"style="margin-top: 10px;"><h6>₹{{subTotal}}.00</h6></div>

                        </div>
                        </div>


                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <button class="btn btn-success btn-block" style="    padding: 5px;
    width: 58%;background: #2b7689;margin-top: 30px;" (click)="phonepePayment()">
                            CONTINUE <i class="fa fa-angle-right"></i>
                        </button>
                    </div>
                    <div class="col-lg-4">


                    </div>
                    <div class="col-lg-4">


                    </div>



                </div>
            </div>
    </div>
</div>

