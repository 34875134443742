<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}" >
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <h2 class="tittle">Dress<span style="color:#f38100">Box</span></h2>
            <img src="../../../../assets/img/newimages/logo.jpeg" class="dresslogo" />
        </a>
        <div class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="about()">About Us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="Schools()">Schools</span></li>
                <li class="nav-item"><span class="nav-link" (click)="WorkWear()">Work Wear</span></li>
                <li class="nav-item"><span class="nav-link" (click)="contact()">Contact Us</span></li>
                <!--<li class="nav-item"><span class="nav-link" routerLink="/ProductDetails">Product Details</span></li>-->
                <li class="nav-item"><span class="nav-link" (click)="cartdedails()"><i class="fas fa-shopping-cart"></i>({{countReturn()}})</span></li>
                <li class="nav-item" *ngIf="!loginDetails">
                    <div class="dropdown">
                        <button class="dropbtn" routerLink="/Login">
                            Login
                        </button>
                    </div>
                </li>
                <li class="nav-item" *ngIf="loginDetails">
                    <div class="dropdown">
                        <button class="dropbtn" >

                            <h2 class="tooltiptext fontt h2" style="color:white">{{loginDetails[0].Name | slice:0:1}}</h2>

                        </button>
                        <div class="dropdown-content">

                            <a (click)="Address()"><i class="fas fa-location"></i> Add address</a>
                            <a routerLink="/MyOrders"><i class="fas fa-list-alt"></i> My Orders </a>
                            <a (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </div>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="about()">About Us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="Schools()">Schools</span></li>
                <li class="nav-item"><span class="nav-link" (click)="WorkWear()">Work Wear</span></li>
                <li class="nav-item"><span class="nav-link" (click)="contact()">Contact Us</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/ProductDetails">Product Details</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/AddToCart"> <i class="fas fa-shopping-cart"></i></span>({{countReturn()}})</li>
                <li class="nav-item">
                    <div class="dropdown">
                        <button class="dropbtn" routerLink="/Login">Login</button>
                        <div class="dropdown-content">
                            <a href="#"><i class="fas fa-user"></i> Profile</a>
                            <a (click)="Address()"><i class="fas fa-location"></i> Add address</a>
                            <a href="#"><i class="fas fa-list-alt"></i> My Orders </a>
                            <a href="#"><i class="fas fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
<style>
    .dresslogo {
        width: 20% !important;
        border-radius: 50% !important;
    }
    .fas {
        color: #f38100;
        font-size: 20px;
        margin-top: 0px !important;
    }
</style>
