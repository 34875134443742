import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { SchoolsComponent } from './Dress_box_Pages/schools/schools.component';
import { SchoolProductsComponent } from './Dress_box_Pages/school-products/school-products.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { AboutComponent } from './components/common/about/about.component';
import { LoginComponent } from './Dress_box_Pages/login/login.component'
import { RegistrationComponent } from './Dress_box_Pages/registration/registration.component'
import { AddToCartComponent } from './Dress_box_Pages/add-to-cart/add-to-cart.component'
import { MyOrdersComponent } from './Dress_box_Pages/my-orders/my-orders.component'
import { PackagesComponent } from './Dress_box_Pages/packages/packages.component'
import { ProductdetailsComponent } from './Dress_box_Pages/productdetails/productdetails.component';
import { AddressdetailsComponent } from './Dress_box_Pages/addressdetails/addressdetails.component';
import { WorkWearComponent } from './Dress_box_Pages/work-wear/work-wear.component'
import { PackageDetailsComponent } from './Dress_box_Pages/package-details/package-details.component'
import { OrderInvoiceComponent } from './Dress_box_Pages/order-invoice/order-invoice.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { ForgotpasswordComponent } from './Dress_box_Pages/forgotpassword/forgotpassword.component';
const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'OrderInvoice/:OrderInvoice', component: OrderInvoiceComponent },
    { path: 'Packages/:Packagename', component: PackagesComponent},
    { path: 'AddToCart', component: AddToCartComponent},
    { path: 'MyOrders', component: MyOrdersComponent},
    { path: 'Login', component: LoginComponent},
    { path: 'CheckOut', component: BlogComponent},
    { path: 'Registration', component: RegistrationComponent},
    { path: 'About', component: AboutComponent},
    { path: 'Contact', component: ContactComponent},
    { path: 'Schools', component: SchoolsComponent},
    { path: 'WorkWears', component: WorkWearComponent},
    { path: 'PackageProduct/:Package/:Name', component: PackageDetailsComponent},
    { path: 'SchoolProducts/:SchoolName', component: SchoolProductsComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'home-five', component: HomeFiveComponent},
    {path: 'home-six', component: HomeSixComponent},
    {path: 'home-seven', component: HomeSevenComponent},
    {path: 'home-eight', component: HomeEightComponent},
    {path: 'home-nine', component: HomeNineComponent},
    {path: 'home-ten', component: HomeTenComponent},
    {path: 'home-eleven', component: HomeElevenComponent},
    {path: 'home-twelve', component: HomeTwelveComponent},
    {path: 'home-thirteen', component: HomeThirteenComponent},
    { path: 'blog-details', component: BlogDetailsComponent },
   { path: 'ProductDetails/:Product/:Name', component: ProductdetailsComponent },
    { path: 'Address', component: AddressdetailsComponent },
    { path: 'Forgotpass', component: ForgotpasswordComponent },

    // Here add new pages component

    {path: '**', component: HomeOneComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
