import { Component } from '@angular/core';
import { NavigateproService } from '../../../Services/navigatepro.service'
import { GeneralserviceService } from '../../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CartserviceService } from '../../../Services/cartservice.service';
import { Subscription } from 'rxjs';
declare var $: any;
@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent   {
    totalPrice: any;
    OrderDetails: any;
    Id: any;
    deliveryCharge: any;
    offerPrice: any = 0;
    GstPrice: any;
    offerDetails: any;
    offerCode: any;
    WishListitemmsforloginusers: any = [];
    cartIndex: any;
    n: any;
    val: number;
    arr: any = [];
    PaymentType: any;
    addressId: any;
    currentCartItems: any = [];
    customerCartItems: any = [];
    loginStatus: boolean;
    addressDetails: any;
    addressDetails1: any;
    LoginDetails: any;
    itemSize: any;
    ItemId: any;
    seletedItemforWishlist: any = [];
    wishListItems: any = [];
    subTotal: any = 0;
    HomeURL: any;
    cartItems: any;
    Quantity: any;
    offersdata: any;
    offerId: any;
    alert: any;
    //getAllAdminOrders: any;
    plus: boolean = true;
    TransactionStatus: any;
    admin: any;
    loginDetails: any;
    loginDet: any;
    AID: any;
    orderno: any;
    packageitems: any;
    Packagedata: any;
    packagesarr: any;
    Itemdata: any;
    TTprice: any; TaxnId: any;

    constructor(public http: HttpClient, public cartService: CartserviceService,
        
        public generalService: GeneralserviceService, public router: Router) {
        debugger
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)

        this.Packagedata = localStorage.getItem("Packagesarr");
        this.packagesarr = JSON.parse(this.Packagedata)
        
        this.Itemdata = localStorage.getItem("Itemsarr");
        this.packageitems = JSON.parse(this.Itemdata)
        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            this.subTotal += (a.Price * 1) * (a.Quantity)
        })
            // Assuming Price is a number
       
        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });
    }

    ngOnInit(): void {
        debugger
        if (this.loginDetails) {
            this.loginStatus = true;
            this.getCustomerAddress();
        }
        else {
            this.loginStatus = false;
        }

    }

    /*
    Below procedtoBuy() method will fire when the user click on proced to by button
     */
    procedtoBuy() {
        debugger
        if (this.LoginDetails) {
            if (this.addressDetails) {
                if (!this.addressId) {
                    this.generalService.ShowAlert('Warning', 'Please select or add address for Delivery.', 'warning');
                }
                else {

                    $('#myModal').modal('show');

                }
            } else {
                this.generalService.ShowAlert('Warning', 'Please add address for Delivery.', 'warning');
                this.router.navigate(['/Address']);
            }
        }
    }






    deleteFromCart(index) {
        this.cartService.removeCartItems(index)

    }





    getCustomerAddress() {
        debugger

        let formData: FormData = new FormData();
        formData.append('Param', this.loginDetails[0].RegID);
        var url = "api/DressBox/Get_addresses_based_Userid";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {
                this.addressDetails = data;
                this.plus = false
            }
        });
    }


    deleteCartfromTable(index: number, cartId: any) {
        debugger
        this.cartItems.splice(index, 1);

        console.log(`Deleted cart item with ID ${cartId}`);
    }





    sendMailToAdmin(val) {
        debugger

        this.addressDetails1 = []
        this.addressDetails1 = this.addressDetails
        let arr = [];
        arr.push({
            OrderID: this.addressDetails1[0].OrderID,
            FarmerName: this.addressDetails1[0].FarmerName,
            MobileNo: this.addressDetails1[0].MobileNo,
            Area: this.addressDetails1[0].Area,
            City: this.addressDetails1[0].City,
            Email: this.addressDetails1[0].Email,
            //ProductName: this.orderDetails[0].ProductName,

        })
        var UploadFile = new FormData();
        var url = "api/Products/Assginoredr_aftersend_mailToAdmin"

        UploadFile.append("Xml", JSON.stringify(arr));
        UploadFile.append("flag", val);
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;
        })
    }




    /*rediret payment gateway*/
    RedirectToPaymentGateway(CartProducts, n) {
        debugger

        var UploadFile = new FormData();
        this.subTotal = this.totalPrice + this.deliveryCharge;

        UploadFile.append('Name', this.LoginDetails.FarmerName);
        UploadFile.append('Mobile', this.LoginDetails.MobileNo);
        UploadFile.append('Email', this.LoginDetails.Email);
        UploadFile.append('Amount', this.subTotal);
        UploadFile.append('Productinfo', "Product");
        UploadFile.append('TransactionId', n);
        UploadFile.append('BookingDetails', JSON.stringify(CartProducts));
        //alert('Start')
        var url = "/api/Products/PaymentGateWayWEB";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            window.location.href = data.toString();
            this.cancel()
            // alert ('hello')
            // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });

            this.cartService.removeAllcart()
            this.val = 1

            this.router.navigate['/Home'];
            debugger




        }, err => {
            this.router.navigate['/Cart'];
        });

    }
    /*end*/

    PlaceOrder1() {

        debugger
        if (!this.AID) {
            this.generalService.ShowAlert('Warning', 'Please select a Address.', 'Warning');
            return;
        }
        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();

        for (var i = 0; i < this.cartItems.length; i++) {
            this.arr.push({
                CustomerID: this.loginDetails[0].RegID,
                AID: this.AID,
                PaymentType: "OnLinePayment",
                //PaymentType: this.PaymentType,
                ProductId: this.cartItems[i].Productid,
                Quantity: this.cartItems[i].Quantity,
                Size: this.cartItems[i].Size,
                Price: this.cartItems[i].Price,
                TransactionID: this.n,
                PackageID: this.cartItems[i].PackageID,
                TotalPrice: this.cartItems[i].Price * this.cartItems[i].Quantity,
                CreatedBy: this.loginDetails[0].RegID,
                Device: 'Web',
            })
        }
             
        //this.RedirectToPaymentGateway(this.arr, this.n)  paymentgetway commented
        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(this.arr));
        formData.append('Param1', JSON.stringify(this.packageitems));
        var url = "api/DressBox/Insert_Package_CustomerOrders"

        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            this.orderno = data

            if (data.Table2[0].MSG && data.Table2[0].MSG) {
                this.generalService.ShowAlert('OrderNo:' + this.orderno.Table2[0].MSG, 'Your Order is placed.', 'Success');
                this.subTotal=0
                this.cartService.removeAllcart();
                this.cartItems = {};


                this.router.navigate(['/MyOrders']);
                this.cancel()
                    this.val = 1
                    this.sendMailToAdmin(this.val)

                //else {
                //  this.RedirectToPaymentGateway(this.arr, this.n)

                //}

            }

        });
    }
    PlaceOrder() {

        if (!this.AID) {
            this.generalService.ShowAlert('Warning', 'Please select a Address.', 'Warning');
            return;
        }
        debugger

        this.router.navigate(['/Schools']);
        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(this.packagesarr));
        formData.append('Param1', JSON.stringify(this.packageitems));
        var url = "api/DressBox/Insert_Package_CustomerOrders"

        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            this.orderno = data

            if (data.Table2[0].MSG && data.Table2[0].MSG) {
                this.generalService.ShowAlert('OrderNo:' + this.orderno.Table2[0].MSG, 'Your Order is placed.', 'Success');
                this.subTotal = 0
                this.cartService.removeAllcart();
                this.cartItems = {};

                this.router.navigate(['/MyOrders']);

                this.val = 1

            }

        });
    }

    cancel() {
        $('#myModal').modal('hide');
    }


    phonepePayment() {
        debugger
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;
       // const apiUrl = "https://localhost:44374/api/DressBox/PhonepeMakePayment";
        const apiUrl = "https://dressbox.gagriglobal.com/webservices/api/DressBox/PhonepeMakePayment"; // Replace with your actual API URL
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-VERIFY': 'df7191d8bd4018e6b2d00fc4b1b5dbe912efaa24a97e951d47f49e81f4a13930###1',
        }); // Replace with your headers
        const dynamicCallbackUrl = "https://dressbox.gagriglobal.com";
        // Construct the payment request
        var paymentRequest = {
           // merchantId: 'SUBHASANONLINE',
            merchantId: 'M22YXJLE71Y6U',
            merchantTransactionId: this.TaxnId,
            merchantUserId: 'DRESSBOX123',
            amount: '100',
            redirectMode: 'POST',
            redirectUrl: dynamicCallbackUrl,
            callbackUrl: dynamicCallbackUrl,
            mobileNumber: this.loginDetails[0].Mobile,
            paymentInstrument: {
                type: "PAY_PAGE"
            }
        };
        debugger
        this.http.post(apiUrl, paymentRequest, { headers: httpHeaders }).subscribe(
            //Success callback function
            (response: any) => {
                // debugger                
                var redirectUrl = response.data.instrumentResponse.redirectInfo.url;
                window.open(redirectUrl, '_blank');
                localStorage.setItem("TransId", this.TaxnId);
                // this.Gettransactionstatus(this.TaxnId, Package_ID);
            }, (error: any) => {
                console.error('API error:', error);
                //handle the error here
            }
        )
     //   this.PlaceOrder1();
    }


    getSelectedAddress(addressId: any) {
        this.AID = addressId;

    }

    /*
       Below method is used to call the delivery mode whether it is online or offline
    */
    getPaymentType(pType: string) {
        this.PaymentType = pType
    }

    selectmode() {
        debugger
        $('#myModal').modal('show');
    }



    navigatingToProduct(data) {
        var prodName = "";
        var str = data.split(" ")//spliting the product name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.router.navigate(['/Product/' + prodName]); ///sandhya
    }







}



