import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { EmailValidators } from 'ngx-validators'

//import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralserviceService } from '../../Services/generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
declare var $;
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
    HomeUrl: any; arr: any = [];
    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    flag: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true; loginDet: any;
    registerAsDonor: boolean = false; RegId: any;
    LoginDetails: any;
    EmailID: any;
    RegisterForm: any;
    Name: any;
    Email: any;
    Password: any; Mobilenum: any; checkNo: any;
    mobiledata:any;

    constructor(public router: Router, private formBuilder: FormBuilder, public generalService: GeneralserviceService, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });


        this.RegisterForm = this.formBuilder.group({
            Name: ['', Validators.required],
            OTP: ['', Validators.required],
            Mobile: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern('[0-9]*'), Validators.required])],
            email: ['', Validators.compose([Validators.required])], //this.emailValidator
            Password: ['', Validators.compose([Validators.maxLength(30), Validators.minLength(6), Validators.required])],
            //, { validator: this.matchingPasswords('password', 'cpassword') } cpassword: ['', Validators.compose([Validators.maxLength(30), Validators.minLength(6), Validators.required, this.nospaceValidator])]

        });
    }
    ngOnInit() {
        // this.Getsurname();
        //this.OnSubmit();
    }
    OnSubmit() {
        throw new Error('Method not implemented.');
    }


    regform() {

    }

    reg() {
        debugger
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }
    SendOTPtoMobile() {
        debugger
        if (this.checkNo === 10) {
            this.generalService.ShowAlert('error', 'Mobile number already exists.', 'error');
            return;
        }
        //  this.CheckMobileNumber();
        this.Mobilenum = this.RegisterForm.get('Mobile').value
        if (!this.otpSent && this.Mobilenum && this.Mobilenum.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobilenum)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/DressBox/SendOtpToMobile";
            this.generalService.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
                if (this.EmailID != null) {
                  //  this.SendOTPtoMail();
                }
            })
        }
    }

    checkmobile(Mobile) {
        debugger
          if (!this.registerAsDonor) {
        alert('Please click on the checkbox to register as a blood donor.');

        return;
    }
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);


                var url = 'api/DressBox/checking_Mobile'

                this.generalService.PostData(url, UploadFile).then(data => {
                    //this.logindata = JSON.stringify(data[0]);
                    //localStorage.setItem("LoginDetails", this.logindata);
                    debugger
                    if (data != 'NOTEXIST') {
                        debugger
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;
                        this.router.navigate(['/blooddonation']).then(() => { window.location.reload(); });
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);

                    }
                    else {

                        this.router.navigate(['/Donersform']);
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);
                    }
                })
            }
        }
    }

    insertPhoneNumber(val: any) {
        debugger;

        // Check if OTP is provided
        if (!val.OTP) {
            this.generalService.ShowAlert('error', 'Please provide the OTP.', 'error');
            return;
        }

        if (!val.Name || !val.Mobile || !val.email || !val.Password) {
            // Show alert message if any required field is null or empty
            this.generalService.ShowAlert('error', 'Please fill in all required fields.', 'error');
            return;
        }

        // Check if the mobile number is already registered
        if (this.checkNo === 10) {
            this.generalService.ShowAlert('error', 'Mobile number already exists.', 'error');
            return;
        }

        // Construct the data array
        this.arr = [{
            Name: val.Name,
            Mobile: val.Mobile,
            Email: val.email,
            Password: val.Password,
            Status: true
        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');

        var url = 'api/DressBox/Insert_Update_Form';

        // Post the data to the server
        this.generalService.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
                if (this.flag == 1) {
                    this.generalService.ShowAlert("ERROR", 'You have successfully signed in as a Reg form', 'error');
                } else {
                    this.generalService.ShowAlert("SUCCESS", 'You have successfully signed in as a Reg form', 'success');
                    this.router.navigate(['/Address']);
                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                    window.location.reload()
                    this.RegId = data[0].RegId;
                    localStorage.setItem("RegID", this.RegId);
                }
            }
        });
    }


    /*check mobile exist or not*/
    CheckMobileNo() {
        debugger;
        this.Mobilenum = this.RegisterForm.get('Mobile').value
        var UploadFile = new FormData();
        UploadFile.append("Param2", this.Mobilenum);
        UploadFile.append("Param1", "1");
        var url = "api/DressBox/Get_Check_Mobile_Email"
        debugger;
        this.generalService.PostData(url, UploadFile).then(data => {
            this.mobiledata = data;
            debugger;
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
  /*endregion*/

    isRegisterDisabled(): boolean {
        const val = this.RegisterForm.value;
        return !val.Name || !val.Mobile || !val.email;
    }

}
