import { Component } from '@angular/core';
import { NavigateproService } from '../../Services/navigatepro.service'
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import { CartserviceService } from '../../Services/cartservice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.scss'],

})
export class ProductdetailsComponent {
    quantity: number = 1;
    loginDet: any;
    ProductID: any;
    ProductId: any;
    Productdata: any;
    HomeUrl: any;
    arr: any = [];
    Price: any;
    Discount: any;
    ProductName: any;
    Image: any;
    SchoolName: any;
    TypeName: any;
    Name: any;
  
    ProductInfo: any;
    disprice: any;
    tPrice: any;
    gtprice: any;
    RProductdata: any;
    sizedata: any;
    selectedItemforCart: any = []
    cartItemList: any = []
    Productid: any;
    logedinUser: any;
    loginDetails: any;
    Cartimesforloginusers: any[];
    Sname: any;
    message: any;
    beforePrice: any;
    sizeitem: any;
    subscription: Subscription;
    schooldata1: any;
    schooldata: any;
    ww: any;
    schoolname: any;
    constructor(public activeroute: ActivatedRoute,public cartservice:CartserviceService,public generalService: GeneralserviceService, public fb: FormBuilder, public navigateservice: NavigateproService, public http: HttpClient, public router: Router) {

        debugger

        this.Sname = this.activeroute.snapshot.paramMap.get('Product').replace(/\_/g, " ").trim();
       
        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.getproducts()
            }
            else {

                this.Sname = res[0].Name;
                this.getproducts()

            }
        })
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

            this.subscription = this.generalService.message$.subscribe(message => {
                this.message = message;
                // You can add logic to automatically dismiss messages after some time
                setTimeout(() => this.clearMessage(), 5000);
            });
           
            // this.ProductID = this.navigateservice.statusId;
        });
        this.cartservice.getProducts().subscribe(res => {
            this.cartItemList = res /*Getting current cart item lists*/
        })

    }
    clearMessage() {
        this.message = null;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    ngOnInit(): void {

     //   this.getproducts();
        this.getRproducts();
        this.getschool();
    }

    getproducts() {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", this.Sname);
        var url = "api/DressBox/GetProductsdetails_basedonn_productId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No products', 'No Products  ', 'Warning');
            } else {
                debugger
                this.Productdata = data;
                this.Productid = this.Productdata[0].Productid
                this.ProductInfo = null;
                this.Price = this.Productdata[0].Price 
                this.Discount = this.Productdata[0].Discount
                this.ProductName = this.Productdata[0].ProductName
                this.Image = this.Productdata[0].Image
                this.SchoolName = this.Productdata[0].SchoolName
                this.TypeName = this.Productdata[0].TypeName
                this.ProductInfo = decodeURIComponent(this.Productdata[0].ProductInfo)

                this.disprice = this.Price - (this.Price * (this.Discount / 100));
              
                // Calculate total price (original price + discounted price)
                
                this.gtprice = this.Price;
                this.getsizes(this.Productdata[0].TypeID)
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait a minute', 'error');
        });
    }

    updatePrice() {
        debugger
        this.Price = this.gtprice * this.quantity;
    }

    
    Sizename(Name) {
        debugger
        this.sizeitem = Name
    }
    navigateproduct(data, name) {
        debugger

        this.Sname = data
        this.router.navigate(['/ProductDetails/' + data, name]);
    }

    
    getRproducts() {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", this.Sname);
        var url = "api/DressBox/RProducts";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No products', 'No Products  ', 'Warning');
            } else {
                debugger
                this.RProductdata = data;
              
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait a minute', 'error');
        });
    }

    getschool() {
        debugger
        this.arr = []
        this.arr.push({
            RegID: '1',
            TokenID: 'f3500ea6-d02e-48e5-a2c9-e9a8212f1b59',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/DressBox/School_Master";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.schooldata1 = data;
            this.ww = this.schooldata1.filter(a => a.CID == 1)
            this.schooldata = this.ww
            this
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }
    getsizes(val) {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", val);
        var url = "api/DressBox/Get_sizes_basedon_TypeId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No Sizes', 'No Sizes in this Product', 'error');
            } else {
                this.sizedata = data;
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait a minute', 'error');
        });
    }
    logedin() {
        this.logedinUser = localStorage.getItem('TokenID');
        return this.logedinUser;
    }
    navigatingtoCategory(data) {
        debugger
        this.Productdata[0].SchoolName = data;
        this.router.navigate(['/SchoolProducts/' + data]);
        localStorage.setItem('schoolname', data);
    }

    Addtocart(item: any) {
        debugger;
       
       

        // Check if size/type is not selected
        if (!this.Name) {
            this.generalService.ShowAlert('Warning', 'Please select a size.', 'Warning');
            return;
        }

        // Check if item already exists in cart
        const existingItem = this.cartservice.cartItemList.find(cartItem => cartItem.Productid === item.Productid);
        if (existingItem) {
            // Show warning message
            this.generalService.ShowAlert('Warning', 'This item is already in the cart.', 'warning');
            return; // Do not add the item again
        }

        // Add the item to the cart
        this.selectedItemforCart.push({
            Productid: item.Productid,
            ProductName: item.ProductName,
            TypeName: item.TypeName,
            Image: item.Image,
            Price: item.Price,
            TPrice: this.tPrice,
            Pack: item.Name,
            Size: this.Name,
            Quantity: this.quantity,
        });

        // Insert or add to cart service
        if (this.logedin()) {
            this.insertCartItems(this.selectedItemforCart);
        } else {
            this.cartservice.addtoCart(this.selectedItemforCart[0]);
        }

        // Show success message
        this.generalService.ShowAlert('Success', 'Added to Cart Successfully.', 'success');
        this.selectedItemforCart = []; // Clear selected items
    }


    
    navigatingToPopularProd(item) {
        var catName = "";
        var str = item.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            catName += "_" + str[i];
        }
        this.Sname = item
        this.getproducts();
        this.router.navigate(['/ProductDetails/' + catName]);
    }
    insertCartItems(cartItems) {
        debugger
        this.Cartimesforloginusers = []
        this.Cartimesforloginusers.push(cartItems)
        cartItems[0].UserId = String(this.loginDetails.ID)
        var url = "api/Products/Insert_Cart_Items";
        var UploadFile = new FormData();
        UploadFile.append("Cartxml", JSON.stringify(cartItems));
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data != null) {
              
                var object = data.filter(x => x.Productid == cartItems[0].Productid)[0];
                this.cartservice.addtoCart(object);
                   }
        })
    }
    //sendWhatsapp(item) {
    //    debugger
    //    var prodName = " "
    //    var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
    //    for (let i = 0; i < str.length; i++) {
    //        prodName += "_" + str[i];
    //    }
    //    this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/" + 'ActiveBotanicalPharmaIngredients' + prodName + '/' + item.CasNumber + '/' + item.Specification


    //    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    //}
}
