import { Component } from '@angular/core';
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NavigateproService } from '../../Services/navigatepro.service'
declare var $

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {
    loginDet: any;
    OrderedProductList: any;
    HomeUrl: any;
    arr: any = [];
    schoolpro: any;
    schooldata1: any;
    loginDetails: any;
    ww: any; orderDetails: any;
    productDetails: any;
    constructor(public generalService: GeneralserviceService, public fb: FormBuilder, public navigateservice: NavigateproService, public http: HttpClient,
        public router: Router) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

    }
    ngOnInit(): void {

        this.getOrders();

    }

    getOrders() {
        debugger
       
        var UploadFile = new FormData()
        UploadFile.append("Param", this.loginDetails[0].RegID);
       
        var url = "api/DressBox/Get_All_Customer_Orders_Basedon_CustomerID";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.OrderedProductList = data;
          
            this
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }


   

    navigateToInvoice(OrderID) {
        this.router.navigate(['/OrderInvoice/',OrderID]);
    }
    getProductDetails(obj: any) {
        debugger
        this.productDetails = obj;
        var arr = [];
        arr.push({
            TokenID: this.loginDetails[0].Token,
            UserId: this.loginDetails[0].Id,
            OrderID: this.loginDetails[0].OrderID
        })
        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(arr));
        var url = "/api/Products/Get_Admin_Orderdetails_BasedOn_OrderId"
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            if (data != "") {
                this.orderDetails = [];
                this.orderDetails = data;
                //console.log(this.orderDetails);
            }
        });
        //console.log(this.productDetails);
    }

   
}
