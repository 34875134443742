
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li><a routerLink="/Schools"><b style="color:white;">Schools / </b></a></li>
                <li (click)="navigatingtoCategory(this.Productdata[0].SchoolName)"><b style="color:white;">Products</b></li>
                <div>Product Details</div>
            </ul>
        </div>
    </nav>


    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="card1 card_border single "style="border-radius:5px">
                    <div class="hover01 column">
                        <div *ngIf="Image != null ||Image != '' ">
                            <figure> <img src="{{HomeUrl+Image}}" class="card_image" alt="DB" *ngIf="Image"></figure>
                        </div>
                        <div *ngIf="Image == null || Image == '' ">
                            <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="col-lg-6">
                <div class="paddingg">
                    <!--<p>Discount:<b>{{Discount}}%</b></p>-->
                    <!--<del>Rs-{{gtprice}}</del>-->
                    <!--<h4>{{ProductName}}</h4><br />
                    <p>School/Industry:<b>D.P.S SCHOOL</b> </p>
                    <p>Type Name:<b>Shirt</b></p>
                    <p> <span class="amount">Price: ₹ {{Price}}.00</span></p>
                    <p>Gender:<b>male</b></p>-->
                    
                   
                    <div class="row">
                        <div class="col-lg-4 col-xs-6 col-6 xspadding">Product Name</div>
                        <div class="col-lg-8 col-xs-6 col-6 xspadding">:&nbsp;&nbsp;<b>{{ProductName}}</b></div>
                        <div class="col-lg-4 col-xs-6 col-6 xspadding">School/Industry</div>
                        <div class="col-lg-8 col-xs-6 col-6 xspadding">:&nbsp;&nbsp;<b>D.P.S SCHOOL</b></div>
                        <div class="col-lg-4 col-xs-6 col-6 xspadding">Type Name</div>
                        <div class="col-lg-8 col-xs-6 col-6 xspadding">:&nbsp;&nbsp;<b>Shirt</b></div>
                        <div class="col-lg-4 col-xs-6 col-6 xspadding">Price</div>
                        <div class="col-lg-8 col-xs-6 col-6 xspadding">:&nbsp;&nbsp;<b>₹{{ Price }}</b></div>
                        <div class="col-lg-4 col-xs-6 col-6 xspadding">Gender</div>
                        <div class="col-lg-8 col-xs-6 col-6 xspadding">:&nbsp;&nbsp;<b>Male</b></div>
                        <div class="col-lg-12 col-xs-12 xspadding"><p>This price is inclusive of all Taxes</p></div>
                        <div class="col-lg-12 col-xs-12 xspadding"> <h4>Available Options </h4></div>
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-4  xspadding col-6">
                            <label for="typeId">Select Size:</label>
                            <br />
                            <select id="typeId" style="cursor:pointer" [(ngModel)]="Name" required>

                                <option *ngFor="let type of sizedata" [value]="type.Name">{{ type.Name }} </option>
                            </select>
                        </div>
                        <div class="col-lg-4  xspadding col-6">
                            <label for="quantity">Quantity</label><br />
                            <select name="quantity" id="quantity" [(ngModel)]="quantity" (click)="updatePrice()" required style="cursor:pointer">
                                <option value="" disabled selected hidden>Choose an option</option>
                                <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" [value]="i">{{i}}</option>
                            </select>

                        </div>

                        <button (click)="Addtocart(Productdata[0])"><i class="fas fa-shopping-cart" style="color:white"></i> Add to cart</button>
                    </div>
                </div>
            </div>
            <hr />
            <div class="col-lg-12">
                <h4>Product Details</h4>
                <p class="text-align_j" [innerHtml]="ProductInfo">


                </p>
            </div>
            <hr />
            <div class="col-lg-12">
                <h4>Recommended Product </h4><br />
            </div>
            <div class="row">
                  <!--<app-relatedproducts [(ProductId)]="ProductId" (someEvent)="navigatingToPopularProd($event)"></app-relatedproducts>-->
                   
                    <div class="col-lg-3 col-md-2 col-sm-2 col-xs-2" *ngFor="let item of RProductdata">
                        <div class="card card_border single">
                            <div class="card-bodyy hover01 column" (click)="navigateproduct(item.Productid,item.ProductName)">
                                <div class="demoWrapper">
                                    <div *ngIf="item.Image != null ||item.Image != '' ">
                                        <figure>   <img src="{{HomeUrl+item.Image}}" class="card_image" alt="DB" *ngIf="item.Image"></figure>
                                    </div>
                                    <div *ngIf=" item.Image == null || item.Image == '' ">
                                        <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                                    </div>
                                </div>
                                <hr class="hrl" />
                                <h5 class="card-titlle a_g99">{{item.ProductName}}</h5>
                                <div style="color:black"><b>Rs /{{item.Price}}.00</b></div>
                                <br />

                            </div>

                        </div>
                    </div>

            </div>
        </div>
    </div>
    </div>
