
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li (click)="navigatingtoCategory(this.Productdata[0].SchoolName)"><b style="color:white;">Packages / </b></li>
                <li aria-current="page"><b style="color:white;">Packagedetails</b></li>

            </ul>
        </div>
    </nav>


    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="card card_border single " style="border-radius:5px">
                    <div class="hover01 column">
                        <div *ngIf="Image != null ||Image != '' ">
                            <figure> <img src="{{HomeUrl+Image}}" class="card_image" alt="DB" *ngIf="Image"></figure>
                        </div>
                        <div *ngIf="Image == null || Image == '' ">
                            <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <h4>{{PakageName}}</h4><br />

                <p> <span class="amount"> ₹ {{TPrice}}.00</span></p>
                <p>This price is inclusive of all Taxes</p>
                <h4>Package Items </h4>
                <br />
                <div class="row" *ngFor="let item of itemsdata">
                    <div class="col-lg-2"> <img src="{{HomeUrl+item.Image}}" alt="DB" style="    width: 59px;"></div>

                    <div class="col-lg-4">{{item.ProductName}}</div>

                    <div class="col-lg-4">
                        <label for="typeId"> Size:</label>
                        <br />
                        <select id="typeId" required (change)="selectListType($event.target.value, item)">
                            <option value=" "></option>
                            <option *ngFor="let type of sizedata">{{ type.Name }} </option>
                        </select>
                    </div>


                </div>

            </div>
            <div class="col-lg-2">
                <label for="quantity">Quantity</label><br />
                <select name="quantity" id="quantity" [(ngModel)]="quantity" (change)="updatePrice()" required>
                    <option value="" disabled selected hidden>Choose an option</option>
                    <option *ngFor="let qty of quantities" [value]="qty">{{ qty }}</option>
                </select>

            </div>
            <!--<div class="col-lg-2">
        <h4>Address</h4>
        <div class="card" style="padding:5px;margin-top:3%" *ngFor="let item of  addressDetails let i=index">
            <span>
                <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.AID}}" (change)="getSelectedAddress(item.AID)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
                &nbsp;{{item.Area}}, {{item.HNo}}, {{item.Landmark}},<br />
                {{item.City}},{{item.State}},{{item.Address}}
            </span>
        </div>
    </div>-->

            <div class="col-lg-2">

                <div class="card" style="padding:5px;margin-top:3%;width:160%">
                    <h4>Price Details</h4>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6"><div>Subtotal: </div></div>
                        <div class="col-lg-6"><h6> ₹{{Price}}.00</h6></div>

                        <div class="col-lg-6"><div>Delivery Charges</div></div>
                        <div class="col-lg-6"><h6>Free</h6></div>
                        <hr /> <div class="col-lg-6"> <div>Total Payable:</div> </div>
                        <div class="col-lg-6"><h6> ₹{{Price}}.00</h6></div>

                    </div>


                </div>
                <div class="row">
                     <div class="col-lg-6">
                        <button (click)="Addtocart(Productdata[0])" style="    padding: 5px;
    width: 218%;
    background: #2b7689;
    margin-top: 30px;"><i class="fas fa-shopping-cart" style="color:white"></i> Add to cart</button>

                        

                    </div>



                </div>
            </div>
           
        </div>

        <hr />
        <div class="col-lg-12">
            <h4>Package Details</h4>
            <p class="text-align_j" [innerHtml]="Description">


            </p>
        </div>


        <hr />
        <div class="col-lg-12">
            <h4>Recommended Product </h4><br />
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-2 col-sm-2 col-xs-2" *ngFor="let item of RProductdata">
                <div class="card card_border single">
                    <div class="card-bodyy hover01 column" (click)="navigateproduct(item.Productid)">
                        <div class="demoWrapper">
                            <div *ngIf="item.Image != null ||item.Image != '' ">
                                <figure>   <img src="{{HomeUrl+item.Image}}" class="card_image" alt="DB" *ngIf="item.Image"></figure>
                            </div>
                            <div *ngIf=" item.Image == null || item.Image == '' ">
                                <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                            </div>
                        </div>
                        <hr class="hrl" />
                        <h5 class="card-titlle a_g99">{{item.ProductName}}</h5>
                        <div style="color:black"><b> ₹ {{item.Price}}.00</b></div>
                        <br />

                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
