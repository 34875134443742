import { Component } from '@angular/core';
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NavigateproService } from '../../Services/navigatepro.service'
declare var $
@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss']
})
export class SchoolsComponent {
    loginDet: any;
    schooldata: any;
    HomeUrl: any;
    arr: any = [];
    schoolpro: any;
    schooldata1: any;
    ww: any;
    constructor(public generalService: GeneralserviceService, public fb: FormBuilder, public navigateservice: NavigateproService, public http: HttpClient, public router: Router) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
       
    }
    ngOnInit(): void {

        this.getschool();
       
    }

    getschool() {
        debugger
        this.arr = []
        this.arr.push({
            RegID: '1',
            TokenID:'f3500ea6-d02e-48e5-a2c9-e9a8212f1b59',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/DressBox/School_Master";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.schooldata1 = data;
            this.ww = this.schooldata1.filter(a=>a.CID==1)
                this.schooldata = this.ww
            this
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }


    //navigateproduct(val: any) {
    //    debugger
    //    this.navigateservice.setStatusId(val);
    //    this.router.navigate(['/SchoolProducts']);
    //}


    navigateproduct(data) {
        debugger

        this.router.navigate(['/SchoolProducts/' + data]);
         localStorage.setItem('schoolname', data);

    }

    //sendWhatsapp(item) {
    //    debugger
    //    var prodName = " "
    //    var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
    //    for (let i = 0; i < str.length; i++) {
    //        prodName += "_" + str[i];
    //    }
    //    this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/" + 'ActiveBotanicalPharmaIngredients' + prodName + '/' + item.CasNumber + '/' + item.Specification


    //    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    //}
}
