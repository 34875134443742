




<!--this code use for google analyticals--><div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/Schools"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">My Orders</b></li>
               
            </ul>
        </div>
    </nav>

    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-2 col-sm-2 col-xs-2 col-xs-6" *ngFor="let item1 of OrderedProductList;let i=index">
                
                    <div class="card" style="margin-bottom:1%;" (click)="navigateToInvoice(item1.OrderID)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <h5 style="color: #fd6a4e;">
                                    {{item1.OrderID}}
                                </h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <h5 style="float: right;font-size:16px;">
                                    {{item1.CreatedDate | date:'dd-MMM-yyyy hh:mm a'}}
                                </h5>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-lg-5">
                                <h2 class="cardItems">{{item1.StatusName}}</h2>
                            </div>
                            <div class="col-lg-7">
                                <div>
                                    <h2 class="cardItems1">
                                        Order Amount:{{item1.TotalPrice}}/-
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>


    </div>












