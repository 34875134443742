import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router'
import { CartserviceService } from '../../../Services/cartservice.service'
import { GeneralserviceService } from '../../../Services/generalservice.service';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    cartItemsCount: any;
    loginDet: any;
    loginDetails: any;
    cartItems: any;
    

    constructor(public generalService: GeneralserviceService,public cartservice: CartserviceService, private viewportScroller: ViewportScroller, public router: Router) {

        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.cartservice.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
    }
         // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        debugger
        const storedCount = localStorage.getItem('cartItemsCount');
        if (storedCount) {
            this.cartItemsCount = storedCount;
        } else {
            // Fetch the count from the cart service if not stored
            this.cartservice.getProducts().subscribe(res => {
                this.cartItemsCount = res.length;
                // Store the count in localStorage
                localStorage.setItem('cartItemsCount', this.cartservice.cartItemList);
            });
        }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    Schools() {
        this.router.navigate(['/Schools']);
    } home() {
        this.router.navigate(['/']);
    } about() {
        this.router.navigate(['/About']);
    } contact() {
        this.router.navigate(['/Contact']);
    }
    Address() {
        this.router.navigate(['/Address']);
    } WorkWear() {
        this.router.navigate(['/WorkWears']);
    } Orders() {
        this.router.navigate(['/Orders']);
    }
    logout() {
        this.cartservice.removeAllcart();
        this.loginDetails = null;        
        localStorage.removeItem("LoginDetails");
      //  localStorage.removeItem("cartItems");
        this.router.navigate(['/Login']);
    }
    cartdedails() {
        debugger
        if (!this.loginDetails) {
            this.generalService.ShowAlert('Warning', 'You are not logged in. Please login', 'Warning');

            this.router.navigate(['/Login']);

        }
        else if (this.cartItems === undefined || !this.cartItems || this.cartItems.length === 0) {
            this.generalService.ShowAlert('Warning', 'No Products in the Cart', 'Warning');
        } else  {
            this.router.navigate(['/AddToCart']);
        }
    }
    countReturn() {

        
        this.cartservice.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

            this.cartItemsCount = res.length
            return this.cartItemsCount
        })
        return this.cartItemsCount
    }
}
