import { Component } from '@angular/core';
import { NavigateproService } from '../../Services/navigatepro.service'
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CartserviceService } from '../../Services/cartservice.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent {
    quantity= 1;
    loginDet: any;
    ProductID: any;
    Productdata: any;
    HomeUrl: any;
    arr: any = [];
    Itemarr: any = [];
    Price: any;
    Discount: any;
    ProductName: any;
    Image: any;
    SchoolName: any;
    TypeName: any;
    Name: any;
    PackageItems: any;

    ProductInfo: any;
    disprice: any;
    TPrice: any;
    gtprice: any;
    RProductdata: any;
    sizedata: any;
    selectedItemforCart: any = []
    cartItemList: any = []
    Productid: any;
    logedinUser: any;
    loginDetails: any;
    Cartimesforloginusers: any[];
    Sname: any;
    message: any;
    beforePrice: any;
    Description: any;
    PackageDiscount: any;
        sizeitem: any;
    PakageName: any;
    GenderType: any;
    subscription: Subscription;
    itemsdata: any;
    addressId: any;
    AID: any;
    addressDetails: any;
    plus: boolean;
    cartItems: any;
    subTotal: number;
    orderno: any;
    val: number;
    PaymentType: any;
    n: any;
    Size: any;
    UPID: any;
    orderitem: { ProductName: string, Name: string, TypeID: string }[] = []; // Initialize orderitem array
    quantities: number[] = [];
    constructor(public activeroute: ActivatedRoute, public cartservice: CartserviceService, public generalService: GeneralserviceService, public fb: FormBuilder, public navigateservice: NavigateproService, public http: HttpClient, public router: Router) {

        debugger
        for (let i = 1; i <= 100; i++) {
            this.quantities.push(i);
        }
        this.Sname = this.activeroute.snapshot.paramMap.get('Package').replace(/\_/g, " ").trim();

        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.getproducts();
                this.getitems();
            }
            else {

                this.Sname = res[0].Name;
                this.getproducts();
                this.getitems();
            }
        })
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

            this.subscription = this.generalService.message$.subscribe(message => {
                this.message = message;
                // You can add logic to automatically dismiss messages after some time
                setTimeout(() => this.clearMessage(), 5000);
            });

            // this.ProductID = this.navigateservice.statusId;
        });
        this.cartservice.getProducts().subscribe(res => {
            this.cartItemList = res /*Getting current cart item lists*/
        })

    }
    clearMessage() {
        this.message = null;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    ngOnInit(): void {


        this.getRproducts();
        this.getCustomerAddress();
    }

    updatePrice() {
        debugger
        this.Price = this.TPrice * this.quantity;
    }
    getSelectedAddress(addressId: any) {
        this.AID = addressId;
        //console.log(addressId)
    }
    selectListType(selectedName: string, item: any) {
        debugger
        const selectedType = this.sizedata.find(type => type.Name === selectedName);
        if (selectedType) {
            this.orderitem.push({
                ProductName: item.ProductName,
             
                Name: selectedName,
                TypeID: selectedType.TypeID
            });
        } else {
            console.error('Selected type not found in sizedata:', selectedName);
        }
    }
    Addtocart(item: any) {
        debugger;


        const selectedSize = (document.getElementById('typeId') as HTMLSelectElement).value;

        // Check if a size is selected
        if (!selectedSize || selectedSize.trim() === '') {
            // Size is not selected, show alert message
            this.generalService.ShowAlert('Warning', 'Please select a size.', 'warning');
            return;
        }

        // Find the corresponding type object based on the selected size
        const selectedType = this.sizedata.find(type => type.Name === selectedSize.trim());

        // If the selected size is not found in sizedata, handle the error
        if (!selectedType) {
            console.error('Selected size not found in sizedata:', selectedSize);
            // Show an alert message or handle the error as needed
            return;
        }

        // Add the item to the cart
        this.orderitem.push({
            ProductName: item.ProductName,
            Name: selectedSize,
            TypeID: selectedType.TypeID
            // You can add other properties here if needed
        });

        // Show success message
        this.generalService.ShowAlert('Success', 'Added to Cart Successfully.', 'success');

      
      

        // Check if item already exists in cart
        const existingItem = this.cartservice.cartItemList.find(cartItem => cartItem.PackageID === item.UPID);
        if (existingItem) {
            // Show warning message
            this.generalService.ShowAlert('Warning', 'This Package is already in the cart.', 'warning');
            return; // Do not add the item again
        }

        // Add the item to the cart
        this.selectedItemforCart.push({
            Productid: item.Productid,
            ProductName: item.PakageName,
            TypeName: item.TypeName,
            Image: item.Image,
            Price: this.Price ,
            TPrice: this.TPrice,
            PackageID: this.UPID,
            Size: this.Name,
            Quantity: this.quantity,
        });

        // Insert or add to cart service
        if (this.logedin()) {
            //this.insertCartItems(this.selectedItemforCart);
        } else {
            this.cartservice.addtoCart(this.selectedItemforCart[0]);
        }

        // Show success message
        this.generalService.ShowAlert('Success', 'Added to Cart Successfully.', 'success');
        localStorage.setItem("Itemsarr", JSON.stringify(this.orderitem));
        this.selectedItemforCart = []; // Clear selected items
    }
    PlaceOrder() {
        if (!this.loginDetails) {
            this.generalService.ShowAlert('Warning', 'You are not Logined Please login', 'Warning');

            this.router.navigate(['/Login']);

        }
       
        
        debugger

        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();

       
            this.arr.push({
                CustomerID: this.loginDetails[0].RegID,
                AID: this.AID,
                PaymentType: "CashOnDelivery",
                PackageID: this.UPID,
                Quantity: this.quantity,               
                Price: this.TPrice,
                TransactionID: this.n,
                TotalPrice: this.Price,
                CreatedBy: this.loginDetails[0].RegID,
            })
        
        localStorage.setItem("Packagesarr", JSON.stringify(this.arr));
        localStorage.setItem("Itemsarr", JSON.stringify(this.orderitem));
        this.router.navigate(['/CheckOut']);


      
    }

    getPaymentType(pType: string) {
        this.PaymentType = 'CasonDelivery'
    }
    getproducts() {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", this.Sname);
        var url = "api/DressBox/GetPackagesbased_on_PackageId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No products', 'No Products  ', 'Warning');
            } else {
                debugger
                this.Productdata = data;
                this.Productid = this.Productdata[0].Productid
                this.UPID = this.Productdata[0].UPID
                this.ProductInfo = null;
                this.Price = this.Productdata[0].Price
                this.TPrice = this.Productdata[0].Price
                this.PackageDiscount = this.Productdata[0].PackageDiscount
                this.PakageName = this.Productdata[0].PakageName
                this.Image = this.Productdata[0].Image
                
                this.GenderType = this.Productdata[0].GenderType
                this.Description = decodeURIComponent(this.Productdata[0].ProductInfo)

            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }

   
    Sizename(Name) {
        debugger
        this.sizeitem = Name
    }
    navigateproduct(val: any) {
        debugger
        this.navigateservice.setStatusId(val);
        this.router.navigate(['/ProductDetails',val]);
    }

    getRproducts() {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", '4');
        var url = "api/DressBox/RProducts";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            
                debugger
                this.RProductdata = data;

            
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }
    getitems() {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", this.Sname);
        var url = "api/DressBox/GetPackageItems_based_on_packageid";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No Items', 'No Items in this Package', 'error');
            } else {
                this.itemsdata = data;
                this.itemsdata.forEach(item => {
                    this.getsizes(item.TypeID);
                });
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }
    getCustomerAddress() {
        debugger

        let formData: FormData = new FormData();
        formData.append('Param', this.loginDetails[0].RegID);
        var url = "api/DressBox/Get_addresses_based_Userid";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {
                this.addressDetails = data;
                this.plus = false
            }
        });
    }
    getsizes(val:any) {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", val);
        var url = "api/DressBox/Get_sizes_basedon_TypeId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No products', 'No products in this school', 'error');
            } else {
                this.sizedata = data;
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }
    logedin() {
        this.logedinUser = localStorage.getItem('TokenID');
        return this.logedinUser;
    }
  

    navigatingtoCategory(data) {
        debugger
        this.Sname = data
        this.router.navigate(['/Packages/' + data]);
        
    }

  
   
    
}
