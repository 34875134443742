<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Packages</b></li>

            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="row" style="background-color:#2b768908;border-radius:5px;padding:5px">
            <div class="col-lg-6">
                <div style="padding:10px;color:black;font-weight:bold"></div>
            </div>

            <div class="col-lg-2">            </div>
            <div class="col-lg-2">    <button class=" btn-danger btn-sm" (click)="refresh()" style="width: 50px; margin-top: 0px; "><i class="fa fa-refresh"></i></button>          </div>


            <div class="col-lg-1 col-xs-6" (click)="selectGender(2)" [ngClass]="{ 'selected': selectedGender === 2 }">
                <div class="icon_user">
                    <i class="fas fa-female"></i>
                    <div class="round1">Girl</div>
                </div>
            </div>
            <div class="col-lg-1 col-xs-6" (click)="selectGender(1)" [ngClass]="{ 'selected': selectedGender === 1 }">
                <div class="icon_user">
                    <i class="fas fa-child"></i>
                    <div class="round1">Boy</div>
                </div>
            </div>

        </div>
    </div><br />
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xs-6" *ngFor="let item of packagedata">
                <div class="card card_border single" style="    margin-bottom: 41px;">
                    <div class="card-bodyy text-center" (click)="navigateproduct(item.UPID,item.PakageName)">
                        <div class="demoWrapper ">
                            <div *ngIf="item.Image != null ||item.Image != '' ">
                                <figure>  <img src="{{HomeUrl+item.Image}}" class="card_image" alt="DB" *ngIf="item.Image"></figure>
                            </div>
                            <div *ngIf=" item.Image == null || item.Image == '' ">
                                <figure> <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                            </div>
                        </div>
                        <hr class="hrl" />
                        <h5 class="card-titlle">{{item.PakageName}}</h5>
                        <br />

                    </div>


                </div>
            </div>
        </div>


    </div>
</div>
