
<div class="container">
    <div class="row">
        <div class="col-lg-3">
            <div class="mt-5 pt-5">
                <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Back</button>
            </div>
        </div>
        <div class="col-lg-8"> </div>
       
    </div>
    <div class="row">
        <div id="print-section">
            <table style="background-color: #fff;">
                <tr>

                    <td style="text-align:center;">
                        <img src="../../../../assets/img/Dressbox/logo.PNG" style="width:10% !important;" alt="Logo">
                    </td>
                </tr>

                <tr>
                    <td style="text-align: center;">
                        <div class="strg"> Tax Invoice</div>
                        <!--<img src="../../../../../assets/img/app/VTlogo.png" style="width:20% !important;" alt="Logo">-->
                    </td>
                </tr>
                <tr>
                    <td class="Item" colspan="5" style="text-align:left">
                        <b class="strg">Bill To:</b><br />
                        <span class="strg"> {{orderDetails[0].Name}} - {{orderDetails[0].Mobile}} </span>
                        <br />
                        {{orderDetails[0].HNo}}, {{orderDetails[0].Landmark}}, {{orderDetails[0].Area}},
                        <br />

                        {{orderDetails[0].City}}, {{orderDetails[0].State}} - {{orderDetails[0].Pincode}}<br />
                        <!--{{GSTNUMBER}}-->
                        <!--<div class="strg" style=" text-align: Right !important">YK mart</div>
                        <div style=" text-align: Right !important">
                            Herbal House, road no 3,servey no 255, Kuntloor
                        </div>
                        <div style=" text-align: Right !important">
                            Telangana
                        </div>-->
                        <!--<div class="strg" style=" text-align: Right !important">
                          GSTIN : 36AHWPM4614R2ZC
                        </div>-->
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style="border:none !important">
                            <table style="width:40%;margin-left: 60%;">
                                <tr style="border:none !important; ">
                                    <td class="td_class1"> <span class="strg">Order Id:</span></td>
                                    <td><span class="ordstrn"> {{orderNo}}</span></td>
                                </tr>

                                <tr>
                                    <td><span class="strg">Order Date:</span></td>
                                    <td><span class="strg"><span class="ordstrn"> {{orderDate | date:'dd-MMM-yyyy'}}</span></span></td>
                                </tr>
                            </table>
                            <table style="width:40%; margin-top: -90px;">
                                <tr>
                                    <td><span class="strg">Invoice No:</span></td>
                                    <td><span class="strg"><span class="ordstrn">IN{{billNo}}</span></span></td>
                                </tr>
                                <tr>
                                    <td><span class="strg">Invoice Date:</span></td>
                                    <td><span class="strg"><span class="ordstrn"> {{orderDate | date:'dd-MMM-yyyy'}}</span></span></td>
                                </tr>
                                <!--<tr>
                                  <td><span class="strg">Invoice Time:</span></td>
                                  <td><span class="strg"><span class="ordstrn"> {{orderDate | date:'hh:mm a'}}</span></span></td>
                                </tr>-->
                            </table>


                        </div>

                    </td>
                </tr>
                <tr>
                    <td class="Item" style="padding:0;">
                        <table style="width:100%;">
                             
                            <tr style="font-family: 'Poppins';
    font-size: 15px;
    color: #000;
    font-weight: bold;
    letter-spacing: 0.5px;">
                                <td style="width:5%; ">S No</td>

                                <td style="width:15%;">Description</td>
                                <td style="width:3%; text-align:center">Quantity</td>
                                <td style="width:10%;text-align:right">Size</td>
                                <td style="width:4%; text-align:right">Price</td>
                                <td style="width:10%;text-align:right">TotalPrice</td>



                            </tr>

                            <tr *ngFor="let item1 of orderDetails;let i=index" style="font-family: Arial;
    font-size: 15px;
    color: #000;
    font-weight: normal;
    letter-spacing: 0.5px;">
                                <td style="width:5%;">{{i+1}}</td>

                                <td style="width:15%;">{{item1.ProductName}}</td> 
                                <td style="width:6%;">{{item1.Quantity}}</td>
                                <td style="width:10%;text-align:right">{{item1.Size }}</td>
                                <td style="width:4%; text-align:right">{{item1.Price}}</td>
                                
                                <td style="width:15%;">{{item1.TotalPrice }}</td>





                            </tr>
                        </table>
                    </td>
                </tr>
                <tr></tr>
                <tr>
                    <td style="padding:0;">
                        <table style="width:100%;">
                            <tr style="font-family: 'Poppins'; font-size: 15px; color: #000; font-weight: bold;letter-spacing: 0.5px;">
                                <td>
                                    <div class="strg" style=" text-align: Right !important"> Total Amount:  {{TotalPrice}}</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div style="text-align:left;">
                            We declare that this invoice shows the actual price of the goods described and that all<br />
                            particulars are true and correct.
                        </div>
                        <div style="text-align:right;">
                            Authorized Signatory
                        </div>
                    </td>
                </tr>
                <tr><td style="text-align:center;"> This is computer generated invoice</td></tr>
                <tfoot>



                </tfoot>
            </table>
            <p style="text-align:right">
                <br />
                <button type="submit" class="btn btn-primary hidden-print" (click)="print()">Print</button>
                &nbsp;
                <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Cancel</button>
            </p>

            <script src="script.js"></script>
        </div>

    </div>
</div>
<!--this code use for google analyticals-->









<style>


    .sticky-container1 {
        padding: 0;
        margin: 500px 0 0;
        position: fixed;
        right: -169px;
        top: 230px;
        width: 210px;
        z-index: 1100;
    }

    .sticky1 {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        margin-top: 20rem;
    }

        .sticky1 li {
            border: 1px solid rgba(18, 54, 84, 0.43);
            list-style-type: none;
            background-color: #fff;
            color: #efefef;
            height: 43px;
            padding: 0;
            margin: 0 0 8px;
            transition: all .25s ease-in-out;
            cursor: pointer;
        }

            .sticky1 li img {
                float: left;
                margin: 5px 5px 5px 4px;
            }

            .sticky1 li p {
                padding-top: 5px;
                margin: 0;
                line-height: 16px;
                font-size: 11px;
            }

                .sticky1 li p a {
                    text-decoration: none;
                    color: #2c3539;
                }

            .sticky1 li p {
                padding-top: 5px;
                margin: 0;
                line-height: 16px;
                font-size: 11px;
            }

            .sticky1 li:hover {
                margin-left: -115px;
            }
</style>

<style>
    .modal-dialog {
        max-width: 60% !important;
        margin: 1.75rem auto !important;
    }

    .card {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
    }

    .custom.tabs-primary {
        background-color: rgba(62, 80, 180, 0) !important;
        border: 1px solid #ffffff !important;
        COLOR: #000 !important;
    }

    .nav-item .nav-link {
        color: rgba(0, 0, 0, 0.8) !important;
    }

        .nav-item .nav-link.active {
            background-color: #ffffff !important;
            border-bottom: 3px solid #9ed9f7 !important;
        }

        .nav-item .nav-link:hover {
            background-color: #9ed9f7 !important;
            border-bottom: 3px solid #9ed9f7 !important;
        }

    .HideDescription {
        width: 100%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .strg_c {
        font-family: poppins;
        font-size: 12px;
        color: #000;
        letter-spacing: 0.5px;
        line-height: 1.8;
        text-align: center;
        padding-top: 0;
    }

    .strg {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        letter-spacing: 0.5px;
        text-align: center;
        padding-top: 0.5rem
    }

    .ordstrn {
        font-family: Arial;
        font-size: 15px;
        color: #000;
        font-weight: normal;
        letter-spacing: 0.5px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid #dddddd;
        text-align: right;
        padding: 8px;
    }

    #plugin {
        height: 30% !important;
        position: absolute;
        width: 100%;
    }
</style>

