<div id="about" class="about-area ptb-100">
    <br />

    <div class="container">
        <div class="row">
            <h3 class="a_heading">ADD ADDRESS</h3>
            <br />
            <div class="col-lg-6 col-md-6 vol-sm-12 col-xs-12">


                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <input type="text" placeholder="Enter Name" class="add_input" [(ngModel)]="Name" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <input class="add_input" type="text" maxlength="10" [(ngModel)]="mobile" placeholder="Enter Mobile No" />
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <label class="head_txt">  </label>
                        <input class="add_input" type="text" placeholder="Enter HNo/Flat No" [(ngModel)]="HNo" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <div class="col-lg-6">
                            <label class="head_txt"> <b class="task">School Name<span style="color:red;">*</span></b></label>
                            <select [(ngModel)]="selectedOption">
                                <option *ngFor="let option of schooldata" [value]="option.ID" (change)="getSelectedschool(option.ID)">{{ option.Name }}</option>
                            </select>

                            <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.CategoryName.touched && Form.controls.CategoryName.errors?.required">CategoryName is required</small>-->

                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <input class="add_input" type="text" placeholder="Enter Landmark" [(ngModel)]="Landmark" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <input class="add_input" type="text" placeholder="Enter City" [(ngModel)]="City" />
                    </div>
                </div>




                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <input class="add_input" type="text" placeholder="Enter State" [(ngModel)]="State" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
                        <input class="add_input" placeholder="Enter Pincode" type="tel" pattern="[0-9]*" maxlength="6" [(ngModel)]="Pincode" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <textarea class="add_input" type="text" placeholder="Enter Address" [(ngModel)]="Address"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <button class="btn-danger btn" (click)="Back()">Cancel</button>
                    </div>

                    <div class="col-lg-6">
                        <button class="btn-success btn" (click)="InsertAdderss(Name, mobile, HNo, Address, Landmark, City, State, Pincode)">Add</button>
                    </div>
                </div>


            </div>


            <div class="col-lg-6">
                <img src="../../../assets/img/Dressbox/location.png" />
            </div>
        </div>
    </div>
</div>

