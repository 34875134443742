import { Component } from '@angular/core';
import { NavigateproService } from '../../Services/navigatepro.service'
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CartserviceService } from '../../Services/cartservice.service';
import { Subscription } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent {
    totalPrice: any;
    OrderDetails: any;
    Id: any;
    deliveryCharge: any;
    offerPrice: any = 0;
    GstPrice: any;
    offerDetails: any;
    offerCode: any;
    WishListitemmsforloginusers: any = [];
    cartIndex: any;
    n: any;
    val: number;
    arr: any = [];
    PaymentType: any;
    addressId: any;
    currentCartItems: any = [];
    customerCartItems: any = [];
    loginStatus: boolean;
    addressDetails: any;
    addressDetails1: any;
    LoginDetails: any;
    itemSize: any;
    ItemId: any;
    seletedItemforWishlist: any = [];
    wishListItems: any = [];
    subTotal: any = 0;
    HomeURL: any;
    cartItems: any;
    Quantity: any;
    offersdata: any;
    offerId: any;
    alert: any;
    //getAllAdminOrders: any;
    plus: boolean = true;
    TransactionStatus: any;
    admin: any;
    loginDetails: any;
    loginDet: any;
    AID: any;
    orderno: any;
    sclname: any;
    Price: any;
    gtprice: any;
    quantity: any; TaxnId: any;
    Grandtotal = 0;
    Gamount: any;
    items: any;
    constructor(public http: HttpClient, public cartService: CartserviceService,
        
        public generalService: GeneralserviceService, public router: Router) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.sclname=localStorage.getItem('schoolname');
        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res
            this.changeQuantity()
            this.items = this.cartItems
        })
        this.cartItems.forEach((item: any) => {
            // Assuming Price is a number
        });
        
        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });
    }

    ngOnInit(): void {
        debugger
        if (this.loginDetails) {
            this.loginStatus = true;
            this.getCustomerAddress();            
        }
        else {
            this.loginStatus = false;
        }

    }

    /*
    Below procedtoBuy() method will fire when the user click on proced to by button
     */
    procedtoBuy() {
        debugger
        if (this.LoginDetails) {
            if (this.addressDetails) {
                if (!this.addressId) {
                    this.generalService.ShowAlert('Warning', 'Please select or add address for Delivery.', 'warning');
                }
                else {

                    $('#myModal').modal('show');

                }
            } else {
                this.generalService.ShowAlert('Warning', 'Please add address for Delivery.', 'warning');
                this.router.navigate(['/Address']);
            }
        }
    }


    navigatae() {
     debugger
        this.router.navigate(['/SchoolProducts/' + this.sclname]);
    }

    calculateSubTotal(item) {
        return (item.Price * item.Quantity);
    }

    /*Below deleteFromCart() method will fire when user delete the product from the cart*/
    deleteFromCart(index) {
        this.cartService.removeCartItems(index)
        
    }

    changeQuantity() {
        debugger
        this.subTotal = 0
        this.totalPrice = 0
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            this.subTotal += (a.Price * 1) * (a.Quantity)
            this.Grandtotal = this.subTotal;
        })
        this.totalPrice = this.subTotal
        //if (this.totalPrice < 5000) {
        this.deliveryCharge = 0;
        this.Grandtotal = this.subTotal;
        this.Gamount = this.Grandtotal
        localStorage.setItem('Grantotal', this.Gamount);
        //  this.totalPrice = this.totalPrice + this.deliveryCharge
        //}
        //else {
        //  this.deliveryCharge = 0;
        //  this.totalPrice = this.totalPrice + this.deliveryCharge
        //}
    }


    /*
     *Get Customer Delivery Address 
     */
    getCustomerAddress() {
        debugger
     
        let formData: FormData = new FormData();
        formData.append('Param', this.loginDetails[0].RegID );
        var url = "api/DressBox/Get_addresses_based_Userid";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {
                this.addressDetails = data;
                this.plus = false
            }
        });
    }
    sendsmsToAdmin() {
        debugger
        this.addressDetails1 = this.addressDetails

        var AdMobile = '9866041417'
        var MessageText = "A new order has been placed by the customer" + ':' + this.LoginDetails.FarmerName + ':' + this.LoginDetails.MobileNo + ':' + this.addressDetails1[0].Area + "." + "Ykmart";
        var UploadFile = new FormData();
        var url = "api/Products/Customer_new_orderPlacement"
        debugger
        UploadFile.append("Name", this.LoginDetails.FarmerName);
        UploadFile.append("Mobile", this.LoginDetails.MobileNo);
        UploadFile.append("ID", this.addressDetails1[0].Area);
        UploadFile.append("AdMobile", AdMobile);
        UploadFile.append("Text", MessageText);

        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;
        })
    }

    deleteCartfromTable(index: number, cartId: any) {
        debugger
        this.cartItems.splice(index, 1);

        console.log(`Deleted cart item with ID ${cartId}`);
        localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
        
        // Check if cartItems is empty
        if (this.cartItems.length === 0) {
            // Clear local storage
            localStorage.removeItem("Packagesarr");
            localStorage.removeItem("cartItems");
            this.generalService.ShowAlert('Warning', 'No items in the cart.', 'warning');

            setTimeout(() => {
                this.router.navigate(['/Schools']);
            }, 100);
        } else {
            // Reload the page if cart still has items
            window.location.reload();
        }

            // Clear cart service
            this.cartService.getProducts();
           
        }
    





 
    sendMailToAdmin(val) {
        debugger

        this.addressDetails1 = []
        this.addressDetails1 = this.addressDetails
        let arr = [];
        arr.push({
            OrderID: this.addressDetails1[0].OrderID,
            FarmerName: this.addressDetails1[0].FarmerName,
            MobileNo: this.addressDetails1[0].MobileNo,
            Area: this.addressDetails1[0].Area,
            City: this.addressDetails1[0].City,
            Email: this.addressDetails1[0].Email,
            //ProductName: this.orderDetails[0].ProductName,

        })
        var UploadFile = new FormData();
        var url = "api/Products/Assginoredr_aftersend_mailToAdmin"

        UploadFile.append("Xml", JSON.stringify(arr));
        UploadFile.append("flag", val);
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;
        })
    }

    deleteaddress(index, Id) {
        this.cartIndex = 0;
        this.cartIndex = index;
        let formData: FormData = new FormData();
        formData.append('Id', Id);
        var url = "api/Products/DeleteAddress"     
        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {

                this.getCustomerAddress();
            }
        });
    }


    /*rediret payment gateway*/
    RedirectToPaymentGateway(CartProducts, n) {
        debugger
  
        var UploadFile = new FormData();
        this.subTotal = this.totalPrice + this.deliveryCharge;

        UploadFile.append('Name', this.LoginDetails.FarmerName);
        UploadFile.append('Mobile', this.LoginDetails.MobileNo);
        UploadFile.append('Email', this.LoginDetails.Email);
        UploadFile.append('Amount', this.subTotal);
        UploadFile.append('Productinfo', "Product");
        UploadFile.append('TransactionId', n);
        UploadFile.append('BookingDetails', JSON.stringify(CartProducts));
        //alert('Start')
        var url = "/api/Products/PaymentGateWayWEB";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger


            window.location.href = data.toString();




            this.cancel()
            // alert ('hello')
            // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });

            this.cartService.removeAllcart()
            this.val = 1

            this.router.navigate['/Home'];
            debugger




        }, err => {
            this.router.navigate['/Cart'];
        });
      
    }
    /*end*/


    PlaceOrder() {     
    
        debugger

        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();
       
        for (var i = 0; i < this.cartItems.length; i++) {
            this.arr.push({
                CustomerID: this.loginDetails[0].RegID,
                AID: this.AID,
                PaymentType: "CashOnDelivery",
                //PaymentType: this.PaymentType,
                ProductId: this.cartItems[i].Productid,
                Quantity: this.cartItems[i].Quantity,
                Size: this.cartItems[i].Size,
                Price: this.cartItems[i].Price, 
                TransactionID: this.n,
                PackageID: 0,
                TotalPrice: this.cartItems[i].Price * this.cartItems[i].Quantity,
                CreatedBy: this.loginDetails[0].RegID,
                Device:'Web'
            })
        }
        localStorage.setItem("Packagesarr", JSON.stringify(this.arr));
        this.router.navigate(['/CheckOut']);
        ////this.RedirectToPaymentGateway(this.arr, this.n)  paymentgetway commented
        //let formData: FormData = new FormData();
        //formData.append('Param', JSON.stringify(this.arr));
        //var url = "api/DressBox/Insert_Customer_Orders"
     
        //this.generalService.PostData(url, formData).then((data: any) => {
        //    debugger
        //    this.orderno = data

        //    if (data.Table2[0].MSG && data.Table2[0].MSG) {
        //        this.generalService.ShowAlert('OrderNo:' + this.orderno.Table2[0].MSG, 'Your Order is placed.', 'Success');
        //        this.subTotal=0
        //        this.cartService.removeAllcart();
        //        this.cartItems = {};
                    
                
        //        this.router.navigate(['/CheckOut']);
        //        this.cancel()
        //            this.val = 1
        //            this.sendMailToAdmin(this.val)
                
        //        //else {
        //        //  this.RedirectToPaymentGateway(this.arr, this.n)

        //        //}

        //    }

        //});
    }

    cancel() {
        $('#myModal').modal('hide');
    }

  
    getSelectedAddress(addressId: any) {
        this.AID = addressId;
        //console.log(addressId)
    }

    /*
       Below method is used to call the delivery mode whether it is online or offline
    */
    getPaymentType(pType:string) {
        this.PaymentType = pType
    }

    selectmode() {
        debugger
        $('#myModal').modal('show');
    }



    navigatingToProduct(data) {
        var prodName = "";
        var str = data.split(" ")//spliting the product name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.router.navigate(['/Product/' + prodName]); ///sandhya
    }

 
   



}


