<div id="about" class="about-area ptb-100">
        <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <img src="../../../assets/img/Dressbox/logo.PNG" />
            </div>
            <div class="col-lg-9">

                <div class="title">
                    <p>Registration</p>
                </div>

                <form [formGroup]="RegisterForm" class="list-form">
                    <div class="user_details">
                        <div class="input_box">
                            <label for="name">Full Name</label>
                            <input type="text" id="name" placeholder="Enter your name" formControlName="Name" required>
                        </div>

                        <div class="input_box">
                            <label for="email">Email</label>
                            <input type="email" id="email" formControlName="email" placeholder="Enter your email" required>
                        </div>
                        <div class="input_box">
                            <label for="phone">Phone Number</label>
                            <input type="tel" id="phone" (input)="CheckMobileNo()" formControlName="Mobile" maxlength="10" placeholder="Enter your number" required>
                            <small class="text-danger" *ngIf="RegisterForm.get('Mobile').touched">
                                <span *ngIf="RegisterForm.get('Mobile').hasError('required')">Mobile is required</span>
                                <span *ngIf="checkNo==10">Mobile Number Already Exists</span>
                            </small>
                        </div>
                         
                        <div  class="input_box">
                            <button (click)="SendOTPtoMobile()">Send otp</button>
                        </div>
                        <div class="input_box">
                            <label for="pass">OTP</label>
                            <input type="text" id="pass" formControlName="OTP" placeholder="Enter your OTP" required>
                        </div>

                        <div class="input_box">
                            <label for="pass">Password</label>
                            <input type="password" id="pass" formControlName="Password" placeholder="Enter your password" required>
                        </div>



                    </div>


                </form>
                <div class="reg_btn">
                    <button  (click)="insertPhoneNumber(RegisterForm.value)">Register</button>
                </div>
            </div></div>
        </div>
    </div>
              <style>
                  @media only screen and (max-width:600px) {
                      .container {
                          width: 100%;
                          border-radius: 0.5rem;
                          box-shadow: none;
                          overflow: hidden;
                          margin-top: 8%;
                      }
                  }

              </style>
               
