import { Component } from '@angular/core';
import { NavigateproService } from '../../Services/navigatepro.service'
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-school-products',
  templateUrl: './school-products.component.html',
  styleUrls: ['./school-products.component.scss']
})
export class SchoolProductsComponent {
    loginDet: any;
    ProductID: any;
    schooldata: any;
    HomeUrl: any;
    Sname: any;
    typedata: any;
    arr: any = [];
    selectedListType: any;
    selectedGender: any | null = null;
    schoolId: any;
    schoolname: any;
    constructor(public generalService: GeneralserviceService, public fb: FormBuilder, public navigateservice: NavigateproService, public http: HttpClient, public router: Router, public activeroute: ActivatedRoute) {

        debugger

        this.Sname = this.activeroute.snapshot.paramMap.get('SchoolName').replace(/\_/g, " ").trim();
        
        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.getschool()
            }
            else {

                this.Sname = res[0].Name;
                this.getschool()

            }
        })
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            
          

           // this.ProductID = this.navigateservice.statusId;
        });

    }
    ngOnInit(): void {

        this.getTypes();

    }

    refresh() {
        this.getschool()
       
    }
    package() {
        this.router.navigate(['/Packages/' + this.Sname]);
    }
    selectGender(gender: any) {
        debugger
        this.selectedGender = gender;
        console.log('Selected Gender:', this.selectedGender);
        this.selectedListType = '';
        this.Filterproducts()
        // You can perform any additional actions here based on the selected gender
    }
    selectListType(type: any) {
        debugger
        this.selectedListType = type;
        this.selectedGender = '';
        this.Filterproducts()
    }
    getschool() {
        debugger
       
        var UploadFile = new FormData()
      
        UploadFile.append("Param", this.Sname );
        var url = "api/DressBox/Schoolsproducts_basedon_Schoolid";
        this.generalService.PostData(url, UploadFile).then((data :any)=> {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No products', 'No products in this school', 'error');
            } else {
                this.schooldata = data;
                this.schoolId = this.schooldata[0].Brandid
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }

    Filterproducts() {
        debugger
        this.schooldata = [];
        var UploadFile = new FormData()

        UploadFile.append("Param", this.selectedListType !== undefined ? this.selectedListType : '');
        UploadFile.append("Param1", this.selectedGender !== undefined || this.selectedGender !== null ? this.selectedGender : '');
        UploadFile.append("Param2", this.Sname);
        var url = "api/DressBox/Get_FilterProducts_basedon_filteritem";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No products', 'No products in this school', 'error');
            } else {
                this.schooldata = data;
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }
    getTypes() {
        debugger
        this.arr = []
        this.arr.push({
            RegID: '1',
            TokenID: 'f3500ea6-d02e-48e5-a2c9-e9a8212f1b59'
        })
        var UploadFile = new FormData()

        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/DressBox/TypeMaster_CRUD";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No products', 'No products in this school', 'error');
            } else {
                this.typedata = data;
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }
    //navigateproduct(val: any) {
    //    debugger
    //    this.navigateservice.setStatusId(val);
    //    this.router.navigate(['/ProductDetails']);
    //}

    navigateproduct(data, name) {
        debugger

        this.router.navigate(['/ProductDetails/' + data, name]);
        localStorage.setItem("priductid", data);
        localStorage.setItem("Name", name);
        
    }

    //sendWhatsapp(item) {
    //    debugger
    //    var prodName = " "
    //    var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
    //    for (let i = 0; i < str.length; i++) {
    //        prodName += "_" + str[i];
    //    }
    //    this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/" + 'ActiveBotanicalPharmaIngredients' + prodName + '/' + item.CasNumber + '/' + item.Specification


    //    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    //}
}
