<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Schools</b></li>
                <div>Schools</div>
            </ul>
        </div>
    </nav>

    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-xs-6" *ngFor="let item of schooldata">
                <div class="card card_border single">
                    <div class="card-bodyy text-center" (click)="navigateproduct(item.Name)">
                        <div class="demoWrapper">
                            <div *ngIf="item.Image != null ||item.Image != '' ">
                                <img src="{{HomeUrl+item.Image}}" class="card_image" alt="DB" *ngIf="item.Image">
                            </div>
                            <div *ngIf=" item.Image == null || item.Image == '' ">
                                <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB">
                            </div>
                        </div>
                        <hr class="hrl" />
                        <h5 class="card-titlle">{{item.Name}}</h5>
                        <br />

                    </div>


                </div>
            </div>
        </div>


    </div>
    </div>
