<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <a routerLink="/">  <img src="../../../../assets/img/newimages/logo.jpeg" class="dresslogo1" /></a>
                <p>Dressbox is a renowned manufacturer of uniforms with over 24 years of experience in the industry. </p>
            </div>

            <div class="col-lg-3">
                <h4>Quick Links</h4><br />
                <div><a routerLink="/">Home</a></div>
                <div><a routerLink="/About">About</a></div>
                <div><a routerLink="/Schools">Schools</a></div>
                <div><a routerLink="/WorkWears">Work Wear</a></div>
                <div><a routerLink="/Contact">Contact Us</a></div>

            </div>
            <div class="col-lg-3">
                <h4>Quick Links</h4><br />
                <div><a target="_blank"  href="PrivacyPolicy.html">Privacy Policy </a></div>
                <div><a target="_blank"  href="TermsAndConditions.html">Terms & Conditions </a></div>
                <div><a target="_blank"  href="Return&Refund.html">Return & Refund Policy </a></div>
                <div><a target="_blank" href="Shipping.html">Shipping Policy </a></div>
                
            </div>
            <div class="col-lg-3">
                <h4>Contact Links</h4><br />
                <div> Vanasthalipuram, Hyderabad 500070 </div>
                <div>+91 9876543210</div>
                <div>Info@dressbox.com</div>
                <ul>
                    <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                    <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                    <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                    <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                    <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
                </ul>
            </div>
        </div>
           </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>
