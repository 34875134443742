<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Add to cart</b></li>
                <div>Add to cart</div>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="container">
            <div class="row">
                <div class="col-lg-10">
                    <table id="cart" class="table table-hover table-condensed">
                        <thead>
                            <tr>
                                <th style="width:40%">Product</th>
                                <th style="width:10%">Size</th>
                                <th style="width:10%">₹ Price</th>
                                <th style="width:8%">Quantity</th>
                                <th style="width:22%" class="text-center">Subtotal</th>
                                <th style="width:10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let items of cartItems;let i=index">
                                <td data-th="Product">
                                    <div class="row">
                                        <div class="col-sm-2 hidden-xs">
                                            <img src={{HomeURL+items.Image}} alt="..." class="img-responsive" />
                                        </div>
                                        <div class="col-sm-10">
                                            <h4 class="nomargin"style="color: black; font-size: large;">{{items.ProductName}}</h4>

                                        </div>
                                    </div>
                                </td>
                                <td class="text-center" data-th="Size">{{items.Size}}</td>
                                <td class="text-center" data-th="Price">{{items.Price}}.00</td> 
                                <td>
                                    <div class="ratefont">
                                        <!--<label for="quantity">Quantity</label><br />-->
                                                                              <select [(ngModel)]="items.Quantity" (change)="changeQuantity()">
                                                                                  <option value="" disabled selected hidden>Choose an option</option>
                                                                                  <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" [value]="i">{{i}}</option>
                                                                              </select>

                                    </div>
                                </td>
        <td class="text-center" data-th="subTotal"  ><h6><b> ₹{{ calculateSubTotal(items) }}</b></h6></td>
        <td class="actions" data-th="">

            <a  (click)="deleteCartfromTable(i,items.CartId)"><i class="fa fa-trash"></i></a>
        </td>
        </tr>
        </tbody>
        <tfoot>
            <tr class="visible-xs">
                <td></td>
                <td></td>
                <td></td>
                <td><strong>GrandTotal  </strong></td>
                <td   class="text-center"><strong> ₹{{Grandtotal}}.00 </strong></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <a (click)="navigatae()" class="btn " style="background-color: #f38100;
    color: white;"><i class="fa fa-angle-left"></i>Continue Shopping</a>
                </td>
                <td colspan="2" class="hidden-xs"></td>
                <td class="hidden-xs text-center"><strong></strong></td>
                <td><a class="btn btn-success btn-block" style="padding:5px;    width: 113%;background: #2b7689;" (click)="PlaceOrder()">Checkout <i class="fa fa-angle-right"></i></a></td>
                <td></td>
            </tr>
        </tfoot>
        </table>
    </div>
                <!--<div class="col-lg-2">
                    <h4>Address</h4>
                    <div class="card"style="padding:5px;margin-top:3%" *ngFor="let item of  addressDetails let i=index">
                        <span>
                            <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.AID}}" (change)="getSelectedAddress(item.AID)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
                            &nbsp;{{item.Area}}, {{item.HNo}}, {{item.Landmark}},<br />
                            {{item.City}},{{item.State}},{{item.Address}}
                        </span>
                    </div>
                </div>-->
            </div>
         
        </div>
    </div>
</div>
<div class="modal" id="myModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Select Delivery mode</h4>



            </div>
            <div class="modal-body">
                <!--<input type="radio" name="mode" (change)="getPaymentType('Online')" /><span>&nbsp;&nbsp;&nbsp;Online payment</span>-->
                <input type="radio" name="mode" (change)="getPaymentType('cashondelivery')" /><span>&nbsp;&nbsp;&nbsp;Cash on delivery</span><br /><br />
                <br />
                <br />
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn btn-success" (click)="PlaceOrder()" style="margin-right:46%;">Ok</button>
                    <button id="closemodal" type="button" class="btn btn-danger" (click)="cancel()" style="margin-left:-43%;">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</div>
